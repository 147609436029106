import * as React from "react"
import { Link } from "gatsby"
import { useState, useEffect } from "react"
import { CheckmarkSharp } from 'react-ionicons'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import ContinueCheckout from '../components/ContinueCheckout.js'
import EndCheckout from '../components/EndCheckout.js'
import Progress from '../components/Progress.js'
import { navigate } from "gatsby"
import { useContext } from 'react'
import { CheckoutContext } from "../context/CheckoutContext"
import CountryCode from '../components/CountryCode.js'
import Auth from '../components/Auth'
import Footer from '../components/Footer.js'




// markup
const Information = () => {
    const [error, setError] = useState(false);
    const phoneFormRef = React.useRef(null);

    const _handleCloseMessage = (e) => {
        e.preventDefault();
        setError(null)
    }

    const context = useContext(CheckoutContext)


    const validateContinuing = (e) => {
        if (context.sessionToken) {
            navigate("/payment")

        } else {
            // phoneFormRef.current.click();
        }
    }

    const _handleOnClick = (e) => {

    }

    const _handleDeliveryToggle = (e) => {
        e.preventDefault();
        context.toggleDelivery();
    }
    const _handleDeliveryRiga = (e) => {
        e.preventDefault();
        if(context.delivery === 'other') {
            context.toggleDelivery();
        }
    }
    const _handleDeliveryOther = (e) => {
        e.preventDefault();
        if(context.delivery === 'riga') {
            context.toggleDelivery();
        }
    }

    const _handleGiftToggle = (e) => {
        e.preventDefault();
        context.setGift(!context.gift);
    }
    const _handleIsNotGift = (e) => {
        e.preventDefault();
        context.setGift(false);
    }
    const _handleIsGift = (e) => {
        e.preventDefault();
        context.setGift(true);
    }

    const handleGiftCountryCode = (e) => {
        e.preventDefault();
        context.setGiftCountryCode(e.target.value);
    }
    const _handleGiftPhone = (e) => {
        e.preventDefault();
        context.setGiftPhone(e.target.value);
    }
    const _handleGiftName = (e) => {
        e.preventDefault();
        context.setGiftName(e.target.value);
    }

    const selected = true;

    return (
        <main class="checkout ">
            <section class="section">

                <div className="container">

                <Helmet title="Heyday ziedu piegāde" defer={false} />
                <Auth required to="/subscribe" />


                <div class="columns is-mobile">
                    <div class="column pt-0">
                        <Progress value={50} />
                    </div>
                    <div class="column is-one-third pt-0">
                        <EndCheckout />
                    </div>
                </div>


                <h1 class="title is-1">Iespējas</h1>

                <div class="content">

                    <div className="columns">
                        <div className="column mb-5">

                            <div class="card" data-sal="slide-up">
                                <div class="card-content">
                                    <p class="title is-5">
                                        Piegāde
                                    </p>
                                    {/* <ul>
                                        <li>Rīgā — bezmaksas</li>
                                        <li>Jūrmalā, Piņķos, Mārupē, Jaunmārupē, Berģos, Baltezerā, Ādažos — €5</li>
                                    </ul> */}


                                    <div class="field" onClick={_handleDeliveryRiga}>
                                        <input class="is-checkradio" id="riga" type="radio" name="delivery" checked={context.delivery === "riga" ? "checked" : ""} />
                                        <label for="riga">Rīgā</label>
                                    </div>
                                    <div class="field" onClick={_handleDeliveryOther}>
                                        <input class="is-checkradio" id="other" type="radio" name="delivery" checked={context.delivery === "other" ? "checked" : ""} />
                                        <label for="other">Piegāde Jūrmalā, Piņķos, Mārupē, Jaunmārupē, Berģos, Baltezerā, Ādažos, Carnikavā</label>
                                    </div>

                                    <hr />

                                    <p>
                                        {context.delivery === "riga" ? "Piegāde Rīgā bezmaksas" : "Piegāde ar kurjeru €5"}
                                    </p>
                                    <p>
                                        Adresi norādi veicot maksājumu.
                                    </p>

                                    {/* <a href="#" class={context.delivery === "other" ? "button is-success is-fullwidth is-rounded" : "button is-fullwidth is-rounded"} onClick={_handleDeliveryToggle}>
                                        <span class="icon is-small pr-5">
                                            {context.delivery === "other" ? <span class="ion-ionic">
                                                <CheckmarkSharp color="#fff" />
                                            </span> : null}

                                        </span>
                                        {context.delivery === "other" ? "Tu izvēlējies šo" : "Izvēlēties"}
                                    </a> */}

                                </div>
                            </div>

                        </div>
                        <div className="column">
                            <div class="card " data-sal="slide-up">
                                <div class="card-content">
                                    <p class="title is-5">
                                        Dāvana
                                    </p>
                                    {/* <ul>
                                        <li>Abonementu saņems cita persona</li>
                                    </ul> */}
                                    <div class="field" onClick={_handleGiftToggle}>
                                        <input class="is-checkradio" id="gift" type="checkbox" name="gift" checked={context.gift ? "checked" : ""} />
                                        <label for="gift">Šis abonements ir dāvana</label>
                                    </div>
{/* 
                                    <div class="field" onClick={_handleIsNotGift}>
                                        <input class="is-checkradio" id="is-gift" type="radio" name="gift" checked={!context.gift  ? "checked" : ""} />
                                        <label for="is-gift">Pērku sev</label>
                                    </div>
                                    <div class="field" onClick={_handleIsGift}>
                                        <input class="is-checkradio" id="not-gift" type="radio" name="gift" checked={context.gift ? "checked" : ""} />
                                        <label for="not-gift">Dāvinu citai personai</label>
                                    </div> */}

                                    

                                    {/* <a href="#" class={context.gift ? "button is-success is-fullwidth is-rounded" : "button is-fullwidth is-rounded"} onClick={_handleGiftToggle}>
                                        <span class="icon is-small pr-5">
                                            {context.gift ? <span class="ion-ionic">
                                                <CheckmarkSharp color="#fff" />
                                            </span> : null}

                                        </span>
                                        {context.gift ? "Tu izvēlējies šo" : "Izvēlēties"}
                                    </a> */}

                                    {context.gift ?

                                        <form onSubmit={null} class="mt-4">
                                            <hr />
                                            <label class="label">Saņēmēja mobilā telefona numurs</label>
                                            <div class="field has-addons">
                                                <div class="control ">
                                                    <span class="select">
                                                        <CountryCode value={context.giftCountryCode} handleChange={handleGiftCountryCode} />
                                                        {/* <select onChange={handleGiftCountryCode} value={context.giftCountryCode ? context.giftCountryCode : ''}>
                                                            <option value="371">+371</option>
                                                            <option value="372">+372</option>
                                                            <option value="373">+373</option>
                                                        </select> */}
                                                    </span>
                                                </div>
                                                <div class={false ? "control is-expanded is-loading" : "control is-expanded"}>
                                                    <input ref={phoneFormRef} class="input" type="tel" placeholder="Telefona numurs" value={context.giftPhone ? context.giftPhone : ''} onChange={_handleGiftPhone} />
                                                </div>

                                            </div>
                                            <p class="">Ar saņēmēju tiks saskaņots piegādes laiks, kā arī mēs zvanīsim saņēmējam piegādes mirklī.</p>


                                            <label class="label">Saņēmēja vārds</label>
                                            <div class="field has-addons">

                                                <div class={false ? "control is-expanded is-loading" : "control is-expanded"}>
                                                    <input ref={null} class="input" type="text" value={context.giftName ? context.giftName : ''} onChange={_handleGiftName} />
                                                </div>

                                            </div>

                                            <div class="control is-hidden">

                                                <button type="submit" ref={null} class={false ? "button is-loading" : "button"}>
                                                    Apstiprināt
                                </button>
                                            </div>

                                        </form>

                                        : null}






                                </div>

                            </div>
                        </div>






                    </div>


                </div>

                </div>
            </section>

            <Footer />
            <ContinueCheckout continue={validateContinuing} />

        </main>
    )
}

export default Information
