import * as React from "react"

const CountryCode = (props) => {

    const _handleChange = (e) => {
        e.preventDefault();
        props.handleChange(e)
    }

    const list = [
        1,
        32,
        33,
        34,
        39,
        41,
        43,
        44,
        45,
        46,
        47,
        48,
        61,
        351,
        352,
        353,
        354,
        358,
        359,
        370,
        371,
        372,
        375,
        377,
        380,
        385,
        386,
        421,
        423
    ]

    return ( <select onChange={_handleChange} value={props.value ? props.value : ''}>{list.map(c => <option key={c} value={c}>+{c}</option>)}</select>)
}

export default CountryCode